a[target='_blank']:after {
  content: '\1F5D7'
}

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm { display: none !important; }

.imageWidth { width: 100%; height: auto; }

.headerText { font-weight: bold }